<template>
  <div>
    <back></back>
    <div class="detail">


      <h3>招标信息：</h3>
      <div class="top">
        <div class="text">
          <p>标号：{{ form.tendersNumber }}</p>
          <p>发布时间：{{ form.createdTime }}</p>
          <p>缴费截止时间：{{ form.payEndTime }}</p>
          <p>状态：<span :class="form.startStatus == 2 ? 'red' : 'gray'">{{ form.startStatus == 1 ? '未开始' :
            form.startStatus
              == 2 ? '进行中' : '已结束' }}</span></p>
        </div>
        <div class="text">
          <p>标书名称：{{ form.tendersName }}</p>
          <p>报名开始时间：{{ form.applicationStartTime }}</p>
          <p>开标时间：{{ form.tendersTime }}</p>
        </div>
        <div class="text">
          <p>招标类型：{{ form.tendersType == 1 ? '商超' : '' }}</p>
          <p>报名结束时间：{{ form.applicationEndTime }}</p>
          <p>中标开始时间：{{ form.effectiveStartTime }}</p>
        </div>
        <div class="text">
          <p>发布人：{{ form.createdUserName }}</p>
          <p>缴费金额：{{ form.payMoney }}</p>
          <p>中标结束时间：{{ form.effectiveEndTime }}</p>
        </div>
      </div>
      <el-divider></el-divider>
      <h3>招标内容：</h3>
      <div class="content" v-html="form.tendersNotice">

      </div>
      <el-divider></el-divider>
      <div class="bottom">
        <!-- <div class="title">招标品类内容：</div> -->
        <h3>招标品类内容：</h3>
        <div>
          <div class="search">
            <el-cascader v-model="categoryId" :options="options" :props="{ expandTrigger: 'hover', label: 'categoryName', value: 'categoryId', emitPath: false }" @change="onSearch" clearable></el-cascader>
            <el-input v-model="shopNumber" placeholder="搜索商品编号" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
            <el-input v-model="shopName" placeholder="搜索商品名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
          </div>
        </div>
      </div>
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%;" max-height="540">
          <el-table-column prop="serialId" label="商品编号"></el-table-column>
          <el-table-column prop="productName" label="商品名称"></el-table-column>
          <el-table-column prop="categoryName" label="分类"></el-table-column>
        </el-table>
      </div>
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
      </el-pagination>
    </div>
  </div>
</template>


<script>
export default {
  data () {
    return {
      tendersId: null,
      loading: false,
      shopName: '',//搜索商品名称
      shopNumber: '',//搜索商品编号
      categoryId: '',//搜索分类id
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
      form: {},
      options: [{
      },
      ],
      tableData: []
    }

  },
  created () {
    this.tendersId = this.$route.query.id
    this.getTendersInfoDetails()
    this.getProductList()
    this.onVisble()
  },
  methods: {
    // 下拉分类
    onVisble () {
      this.$axios.get(this.$api.categoryList, {
        params: {
          auditStatus: 1,
          page: 1,
          pageSize: 9999,
        }
      }).then((res) => {
        this.options = res.data.result.list
      })
    },
    // 详情
    getTendersInfoDetails () {
      this.$axios.get(`${this.$api.tendersInfoDetails}/${this.tendersId}`).then((res) => {
        this.form = res.data.result
      }).catch(() => {

      });
    },
    // 商品列表
    getProductList () {
      this.$axios.get(this.$api.productList, {
        params: {
          page: this.currentPage,
          pageSize: this.pageSize,
          productName: this.shopName,//商品名称
          serialId: this.shopNumber,//商品名称
          categoryId: this.categoryId,//分类id
          saleStatus: 1,
          auditStatus: 1
        }
      }).then((res) => {
        this.tableData = res.data.result.list
        this.totalItemsCount = res.data.result.totalCount
      }).catch(() => {
      });
    },
    // 切换每页显示的条数
    handleSizeChange (e) {
      this.pageSize = e
      this.getProductList()
    },
    // 换页
    handleCurrentChange () {
      this.page += 1
      this.getProductList()
    },
    // 搜索
    onSearch () {
      this.getProductList()
    },


  },
}
</script>

<style lang="scss">
.detail {
  padding: 30px;
  border-radius: 6px;
  border: 1px solid #e2e2e2;



  .top {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;

    .text {
      p {
        margin-bottom: 20px;
        font-size: 16px;
        color: #707070;
      }

      .red {
        color: #EB2500;
      }

      .gray {
        color: #68706E;
      }
    }
  }

  .content {
    margin-top: 30px;

    p {
      img {
        max-width: 1300px;
      }
    }
  }

  .bottom {
    display: flex;
    justify-content: space-between;

    h3 {
      margin-top: 10px;
    }

    .title {
      font-size: 16px;
      font-weight: bold;
    }

    .search {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 44px;
      margin-bottom: 20px;

      .el-input {
        width: 200px;
        margin-left: 20px;
      }

      .el-button {
        margin-left: 20px;
      }
    }
  }
}
</style>